import { useRef, useEffect } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    // Actualiza el título del documento
    document.title = title;

    // Función de limpieza
    return () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, [title, prevailOnUnmount]); // Incluye tanto `title` como `prevailOnUnmount` en las dependencias
}

export default useDocumentTitle;