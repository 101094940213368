import React from "react";

const Footer = () => {
  return (
    <div className="row justify-content-end text-center text-md-left">
      <div
        className="col-lg-3 col-md-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="https://uplanny.com" className="logo">
          <img className="logo" src="images/logo/logo.svg" alt="" />
        </a>
      </div>
      {/* /.about-widget */}
      <div
        className="col-lg-3 col-md-4 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="50"
      >
        <h5 className="footer-title d-none d-md-block">Plataforma</h5>
        <ul>
          <li>
            <a href="#features" >
              Características
            </a>
          </li>
          <li>
            <a href="#apps" >
              Aplicaciones
            </a>
          </li>
          {/* <li>
            <Link to="/blog-details">Soporte</Link>
          </li> */}
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-4 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="footer-title">Recursos</h5>
        <ul>
          {/* <li>
            <Link to="/contacto">Contacto</Link>
          </li> */}
          <li>
            <a href="https://planny.atlassian.net/servicedesk/customer/portal/1" rel="noopener noreferrer" target="_blank">
              Soporte
            </a>

            {/* <Link to="/faq-details"></Link> */}
          </li>
          {/* <li>
            <Link to="/pricing-cs">Planes</Link>
          </li> */}
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-4 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <ul>
          <li>
            <a href={'mailto:hola@uplanny.com'}>hola@uplanny.com</a>
          </li>
          <li>
            <a href="tel:+52 332 515 4811" className="mobile-num">
              +52 332 515 4811
            </a>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default Footer;
 