import React from "react";
//import BrandFour from "../brand/BrandFour";
import FormAppoint from "../form/FormAppoint";
//import { Link } from "react-router-dom";

const HeroBannerNine = () => {
  return (
    <div className="hero-banner-nine lg-container pb-150 pb-md-200 xh-70">
      
{/*       <div class="logo-spinner d-none d-xl-block">
        <img src="images/shape/190.svg"  alt="" />
      </div> */}

      <div className="container-fluid d-flex justify-content-center align-items-center">
        <div className="row">
          {/* Columna 1 con orden 2 en pantallas pequeñas y orden 1 en lg y xl */}
          <div className="col-8 col-lg-6 order-2 order-lg-1 m-auto">
            <h1 className="hero-heading">
              La <span>
                Plataforma <img src="images/shape/189.svg" alt="shape" />
              </span>{" "} de Recursos Humanos para todos tus equipos
            </h1>
            <p className="hero-sub-heading">
              Todo lo que necesitas para gestionar tus procesos de Recursos Humanos en un solo lugar.
            </p>
            <FormAppoint />
            {/* <p className="term-text">
              Already using Deski? <Link to="/login">Sign in.</Link>
            </p> */}
          </div>
          
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            <div className="illustration-container d-none d-lg-block">
              <img src="images/assets/screens/screen-min.svg" alt="screen" />
            </div>
          </div>
        </div>
      </div>
      {/* End .container */}

      {/* <div className="partner-slider-two mt-130 md-mt-100">
        <div className="container">
          <p>
            Simplified scheduling for more than <span>200,000,000</span>{" "}
            meetings
          </p>
          <div className="partnerSliderTwo">
            <BrandFour />
          </div>
        </div>
      </div> */}
      {/* /.partner-slider-two */}
    </div>
    //   /.hero-banner-nine
  );
};

export default HeroBannerNine;
