import React from "react";

const FormAppoint = () => {
  return (
    <div  id="mc_embed_signup">
      <form id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate >
        <div className="form-group mt-4 row">
          <div className="col-12 col-lg-6">
            
          <button 
            type="button" 
            id="mc-embedded-subscribe" 
            className="d-flex justify-content-center align-items-center w-100"
            onClick={() => window.location.href = 'https://app.uplanny.com/auth/sign-up'} // Reemplaza 'https://example.com' con tu URL
          >
            Prueba gratis {/* <img src="images/icon/119.svg" alt="icon" /> */}
          </button>
          </div>
          <div className="col-12">
            <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                <div className="response" id="mce-success-response" style={{display:'none'}}></div>
            </div>
          </div>
        </div>

        {/* <div className="form-group row ">
          <div className="col-12 col-lg-9">
            <FontAwesomeIcon icon={faEnvelope}style={{
                color: "#6563ff", 
                position:'absolute', left: 35, top: 0,
                bottom: 0,
                'max-height': 50,
                height: 50,
                padding: 0
              }}/>
            <input type="email" placeholder="Correo electrónico" className="w-100" />
          </div>
        </div>
        <div className="form-group mt-4 row">
          <div className="col-12 col-lg-4">
            <button className="d-flex justify-content-center align-items-center w-100 ">
            Regístrate 
            </button>
          </div>
        </div> */}

      </form>
    </div>

  );
};

export default FormAppoint;
